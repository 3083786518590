<template>
  <div>
    <VueECharts
      :options="option"
      style="width: 100%; height: 300px"
      @click="click_chart"
    />
    <mylist
      :items="items"
      :title="title_list"
      :headers="header_well"
      :dialog="dialog"
      @close_dialog="close_dialog"
      :key="lkey"
    ></mylist>
  </div>
</template>

<script>
export default {
  components: { mylist: () => import("./StatList.vue") },
  props: {
    type: { type: String, default: "bar" },
    data: Array,
    headers: Array,
    colors: Array,
    title: { type: String, default: "" },
    list: Array,
    list_type: String,
  },
  data() {
    return {
      title_list: "",
      well: {},
      items: [],
      lkey: 0,
      dialog: false,
      option: {
        title: {
          text: this.title,
          left: "left",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "top",
          data: this.headers,
          textStyle: {
            color: "blue",
          },
        },
        //  dataset: { source: this.data },
        series: [
          {
            data: this.data,
            name: this.title,
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            selectedMode: "single",
            emphasis: {
              label: { show: true },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                // formatter: "{b} : {c}",
                formatter: "{d}%",
                position: "inside", //inside
              },
            },
          },
        ],
      },
      header_well: [
        {
          text: "Name",
          value: "wellname",
          selected: true,
        },
        // {
        //   text: "Integrity",
        //   value: "integrity_status_name",
        //   selected: true,
        // },
        {
          text: "lifecycle",
          value: "lifecyclename",
          selected: true,
        },
        {
          text: "Type",
          value: "type_abr",
          selected: true,
        },
        {
          text: "Compliant",
          value: "compliant_chk",
          selected: true,
          type: "check-box",
          slot: "checkbox",
        },

        {
          text: "Days",
          value: "eval_days",
          slot: "cur",
          align: "end",
          dec: 0,
          selected: true,
          sortable: false,
        },
        {
          text: "Oil flow Rate (b)",
          value: "eval_oilflow_rate",
          slot: "cur",
          align: "end",
          total: "oilflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "Gas Flow Rate (cf)",
          value: "eval_gasflow_rate",
          slot: "cur",
          align: "end",
          total: "gasflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "Water Flow Rate (bw)",
          value: "eval_waterflow_rate",
          slot: "cur",
          align: "end",
          total: "waterflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "Oil flow Rate (bpd)",
          value: "oilflow_rate",
          slot: "cur",
          align: "end",
          total: "oilflow_rate",
          selected: false,
          sortable: false,
        },
        {
          text: "Gas Flow Rate (cfpd)",
          value: "gasflow_rate",
          slot: "cur",
          align: "end",
          total: "gasflow_rate",
          selected: false,
          sortable: false,
        },
        {
          text: "Water Flow Rate (bwpd)",
          value: "waterflow_rate",
          slot: "cur",
          align: "end",
          total: "waterflow_rate",
          selected: false,
          sortable: false,
        },

        {
          text: "",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    click_chart(params) {
      if (this.list) {
        this.title_list = params.data.name + "   Wells";
        if (this.list_type == "2")
          this.items = this.list.filter(
            (x) => x.compliant == params.data.type && x.lifecycle == 2
          );
        else if (this.list_type == "1")
          this.items = this.list.filter(
            (x) => x.integrity_status == params.data.type && x.lifecycle == 2
          );
        this.lkey++;
        this.dialog = true;
      }
    },
    close_dialog() {
      this.dialog = false;
    },
  },
};
</script>
